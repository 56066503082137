import type { config } from 'config';
import { organizationsTable } from '#/db/schema/organizations';
import { usersTable } from '#/db/schema/users';
import { attachmentsTable } from './db/schema/attachments';
import { labelsTable } from './db/schema/labels';
import { projectsTable } from './db/schema/projects';
import { tasksTable } from './db/schema/tasks';
import { workspacesTable } from './db/schema/workspaces';

export type EntityTables = (typeof entityTables)[keyof typeof entityTables];

export type FilteredEntityIdFields = {
  [K in keyof typeof entityIdFields]: K extends (typeof config.contextEntityTypes)[number] ? (typeof entityIdFields)[K] : never;
}[keyof typeof entityIdFields];

export type EntityTableNames = EntityTables['_']['name'];

export type MenuSection = (typeof menuSections)[number];
export type MenuSectionName = MenuSection['name'];

// Define entities and their tables
export const entityTables = {
  user: usersTable,
  organization: organizationsTable,
  workspace: workspacesTable,
  project: projectsTable,
  label: labelsTable,
  task: tasksTable,
  attachment: attachmentsTable,
} as const;

// Define fields to identify an entity
export const entityIdFields = {
  user: 'userId',
  organization: 'organizationId',
  workspace: 'workspaceId',
  project: 'projectId',
  label: 'labelId',
  task: 'taskId',
  attachment: 'attachmentId',
} as const;

// Define entities in user menu
export const menuSections = [
  {
    name: 'organizations',
    entityType: 'organization',
  } as const,
  {
    name: 'workspaces',
    entityType: 'workspace',
    submenu: {
      name: 'projects',
      entityType: 'project',
      parentField: 'workspaceId',
    } as const,
  } as const,
];
