import { type QueryKey, useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import { toast } from 'sonner';
import { type GetLabelsParams, deleteLabels as apiDeleteLabels, createLabel, updateLabel } from '~/api/labels';
import type { GetWorkspaceResponse } from '~/api/workspaces';
import { queryClient } from '~/lib/router';
import { taskKeys } from '~/modules/common/query-client-provider/mutations/tasks';
import type { ContextProp, InfiniteQueryData, QueryData } from '~/modules/common/query-client-provider/types';
import { workspaceQueryOptions } from '~/modules/workspaces/helpers/query-options';
import type { Label, Task } from '~/types/app';
import { formatUpdatedData, getQueryItems, getSimilarQueries } from '~/utils/mutate-query';

type LabelsInfiniteQueryData = InfiniteQueryData<Label>;
type LabelsContextProp = ContextProp<Label, string | null> | ContextProp<Task, string | null>;

type LabelsCreateMutationQueryFnVariables = Parameters<typeof createLabel>[0] & {
  workspaceSlug: string;
  id: string;
  entity: 'label';
  lastUsedAt: string;
  isOnline: boolean;
};
type LabelsUpdateMutationQueryFnVariables = Parameters<typeof updateLabel>[0] & {
  workspaceSlug: string;
};
type LabelsDeleteMutationQueryFnVariables = {
  orgIdOrSlug: string;
  ids: string[];
  workspaceSlug: string;
  projectId: string;
};

export const labelKeys = {
  all: () => ['labels'] as const,
  lists: () => ['labels', 'list'] as const,
  table: (filters?: GetLabelsParams) => [...labelKeys.all(), 'table', filters] as const,
  create: () => [...labelKeys.all(), 'create'] as const,
  update: () => [...labelKeys.all(), 'update'] as const,
  delete: () => [...labelKeys.all(), 'delete'] as const,
};

export const useLabelCreateMutation = () => {
  return useMutation<Label, Error, LabelsCreateMutationQueryFnVariables>({
    mutationKey: labelKeys.create(),
    mutationFn: createLabel,
  });
};

export const useLabelUpdateMutation = () => {
  return useMutation<boolean, Error, LabelsUpdateMutationQueryFnVariables>({
    mutationKey: labelKeys.update(),
    mutationFn: updateLabel,
  });
};

export const useLabelDeleteMutation = () => {
  return useMutation<boolean, Error, LabelsDeleteMutationQueryFnVariables>({
    mutationKey: labelKeys.delete(),
    mutationFn: apiDeleteLabels,
  });
};

const getPreviousWorkspace = async (queryKey: QueryKey) => {
  // Cancel any outgoing refetches
  // (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries({ queryKey });
  // Snapshot the previous value
  const previousWorkspace = queryClient.getQueryData<GetWorkspaceResponse>(queryKey);

  return previousWorkspace;
};

const onError = (
  _: Error,
  {
    orgIdOrSlug,
    workspaceSlug,
  }: {
    orgIdOrSlug: string;
    workspaceSlug: string;
  },
  context?: { previousWorkspace?: GetWorkspaceResponse },
) => {
  if (context?.previousWorkspace) {
    const queryOptions = workspaceQueryOptions(workspaceSlug, orgIdOrSlug);
    queryClient.setQueryData(queryOptions.queryKey, context.previousWorkspace);
  }
};

queryClient.setMutationDefaults(labelKeys.create(), {
  mutationFn: createLabel,
  onMutate: async (variables: LabelsCreateMutationQueryFnVariables) => {
    const { orgIdOrSlug, workspaceSlug, isOnline, ...label } = variables;
    const newLabel: Label = {
      ...label,
      color: label.color || null,
      organizationId: orgIdOrSlug,
    };

    const queryOptions = workspaceQueryOptions(workspaceSlug, orgIdOrSlug);
    const previousWorkspace = await getPreviousWorkspace(queryOptions.queryKey);

    // Optimistically update to the new value
    if (!isOnline && previousWorkspace) {
      queryClient.setQueryData<GetWorkspaceResponse>(queryOptions.queryKey, (old) => {
        if (!old) return undefined;

        const updatedLabels = [...old.labels, newLabel];

        return {
          ...old,
          labels: updatedLabels,
        };
      });
    }

    // Return a context object with the snapshotted value
    return { previousWorkspace, optimisticId: label.id };
  },
  onSuccess: (createdLabel, { workspaceSlug, orgIdOrSlug }, { optimisticId }) => {
    const queryOptions = workspaceQueryOptions(workspaceSlug, orgIdOrSlug);

    queryClient.setQueryData<GetWorkspaceResponse>(queryOptions.queryKey, (oldData) => {
      if (!oldData) return undefined;

      const updatedLabels = oldData.labels.map((label) => (label.id === optimisticId ? createdLabel : label));
      return {
        ...oldData,
        labels: updatedLabels,
      };
    });
    toast.success(t('common:success.create_resource', { resource: t('app:label') }));
  },
  onError,
});

queryClient.setMutationDefaults(labelKeys.update(), {
  mutationFn: updateLabel,
  onMutate: async (variables: LabelsUpdateMutationQueryFnVariables) => {
    const { orgIdOrSlug, workspaceSlug } = variables;
    const queryOptions = workspaceQueryOptions(workspaceSlug, orgIdOrSlug);
    const previousWorkspace = await getPreviousWorkspace(queryOptions.queryKey);

    // Optimistically update to the new value
    if (previousWorkspace) {
      queryClient.setQueryData<GetWorkspaceResponse>(queryOptions.queryKey, (old) => {
        if (!old) return undefined;

        const updatedLabels = old.labels.map((label) => {
          if (label.id === variables.id) {
            return {
              ...label,
              ...variables,
            };
          }
          return label;
        });

        return {
          ...old,
          labels: updatedLabels,
        };
      });
    }

    // Return a context object with the snapshotted value
    return { previousWorkspace };
  },
  onError,
});

queryClient.setMutationDefaults(labelKeys.delete(), {
  mutationFn: ({ ids, orgIdOrSlug }: LabelsDeleteMutationQueryFnVariables) => apiDeleteLabels({ ids, orgIdOrSlug }),
  onMutate: async (variables) => {
    const { ids, orgIdOrSlug, projectId, workspaceSlug } = variables;

    const context: LabelsContextProp[] = [];

    // To update labels table data
    const labelsQueries = getTableQueries(orgIdOrSlug, projectId);
    for (const [queryKey, previousLabels] of labelsQueries) {
      // Optimistically update to the new value
      if (previousLabels) {
        queryClient.setQueryData<LabelsInfiniteQueryData>(queryKey, (oldData) => {
          if (!oldData) return oldData;
          const prevItems = getQueryItems(oldData);
          const updatedItems: Label[] = deleteLabels(prevItems, ids);
          return { ...oldData, pages: [{ total: updatedItems.length, items: updatedItems }] };
        });
      }
      context.push([queryKey, previousLabels, null]);
    }

    // to update tasks board & table data
    const boardTaskQueries = getSimilarQueries<Task>(taskKeys.list({ orgIdOrSlug }));
    const tableTaskQueries = getSimilarQueries<Task>(taskKeys.table({ orgIdOrSlug }));

    for (const [queryKey, previousTasks] of [...boardTaskQueries, ...tableTaskQueries]) {
      if (previousTasks) {
        queryClient.setQueryData<InfiniteQueryData<Task> | QueryData<Task>>(queryKey, (oldData) => {
          if (!oldData) return oldData;

          const tasks = getQueryItems(oldData);
          const updatedTasks = tasks.map((task) => {
            // Update the labels in task
            if (task.labels.some((l) => ids.includes(l.id))) {
              // Update task labels by deleting the specified labels
              const updatedTask = { ...task, labels: deleteLabels(task.labels, ids) };

              // Update single task query data with the updated task
              queryClient.setQueryData(taskKeys.single(task.id), updatedTask);

              return updatedTask;
            }

            // No changes, return task as-is
            return task;
          });
          return formatUpdatedData(oldData, updatedTasks);
        });
      }
      context.push([queryKey, previousTasks, null]);
    }

    // to update workspace data
    const workSpaceQueryOptions = workspaceQueryOptions(workspaceSlug, orgIdOrSlug);
    const previousWorkspace = await getPreviousWorkspace(workSpaceQueryOptions.queryKey);
    // Optimistically update to the new value
    if (previousWorkspace) {
      queryClient.setQueryData<GetWorkspaceResponse>(workSpaceQueryOptions.queryKey, (old) => {
        if (!old) return undefined;

        const updatedLabels = deleteLabels(old.labels, ids);
        return {
          ...old,
          labels: updatedLabels,
        };
      });
    }
    return context;
  },
  onSuccess: (_, { ids }) => {
    toast.success(t('common:success.delete_resource', { resource: t(`app:${ids.length > 1 ? 'labels' : 'label'}`) }));
  },
  onError: async (_, __, context) => {
    if (!context || !context.length) return toast.error(t('common:error.delete_resources', { resources: t('app:labels') }));

    for (const [queryKey, prevData] of context) {
      if (prevData) queryClient.setQueryData(queryKey, prevData);
    }
  },
});

const getTableQueries = (orgIdOrSlug: string, projectId: string) => {
  return queryClient.getQueriesData<LabelsInfiniteQueryData>({ queryKey: labelKeys.table({ orgIdOrSlug, projectId }) });
};

function deleteLabels(labels: Label[], ids: string[]) {
  return labels.filter((item) => !ids.includes(item.id));
}
