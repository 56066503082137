import { onlineManager } from '@tanstack/react-query';
import { CommandEmpty } from 'cmdk';
import { Check, Dot, Tag } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useBreakpoints } from '~/hooks/use-breakpoints';
import { useMutateQueryData } from '~/hooks/use-mutate-query-data';
import { dropdowner } from '~/modules/common/dropdowner/state';
import { Kbd } from '~/modules/common/kbd.tsx';
import { useLabelCreateMutation, useLabelUpdateMutation } from '~/modules/common/query-client-provider/mutations/labels';
import { useTaskUpdateMutation } from '~/modules/common/query-client-provider/mutations/tasks';
import { Badge } from '~/modules/ui/badge';
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from '~/modules/ui/command.tsx';
import { useWorkspaceQuery } from '~/modules/workspaces/helpers/use-workspace';
import { useWorkspaceUIStore } from '~/store/workspace-ui.ts';
import type { Label, Task } from '~/types/app';
import { dateIsRecent } from '~/utils/date-is-recent';
import { nanoid } from '~/utils/nanoid';
import { inNumbersArray } from '../helpers';

export const badgeStyle = (color?: string | null) => {
  if (!color) return {};
  return { background: color };
};

interface SetLabelsProps {
  task: Task;
  triggerWidth?: number;
  creationValueChange?: (labels: Label[]) => void;
}

const SetLabels = ({ task, creationValueChange, triggerWidth = 320 }: SetLabelsProps) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoints('max', 'sm');
  const inputRef = useRef<HTMLInputElement>(null);

  const { changeColumn } = useWorkspaceUIStore();
  const {
    data: { workspace, labels },
  } = useWorkspaceQuery();

  const taskMutation = useTaskUpdateMutation();
  const labelCreateMutation = useLabelCreateMutation();
  const labelUpdateMutation = useLabelUpdateMutation();

  const [selectedLabels, setSelectedLabels] = useState<Label[]>(task.labels);
  const [searchValue, setSearchValue] = useState('');
  const [isRecent, setIsRecent] = useState(!!creationValueChange || !isMobile);
  const [isOnline, setIsOnline] = useState(onlineManager.isOnline());
  const createdLabelsRef = useRef<Label[]>([]);

  const mutateQuery = useMutateQueryData(['workspaces', workspace.slug]);

  // Filter and sort labels by `lastUsedAt`.
  const orderedLabels = useMemo(
    () => labels.filter((l) => l.projectId === task.projectId).sort((a, b) => new Date(b.lastUsedAt).getTime() - new Date(a.lastUsedAt).getTime()),
    [labels],
  );

  const showedLabels = useMemo(() => {
    if (searchValue.length) return [...orderedLabels, ...createdLabelsRef.current].filter((l) => l.name.includes(searchValue));
    if (!isRecent) return selectedLabels;
    return orderedLabels.slice(0, 8);
  }, [isRecent, searchValue, orderedLabels, selectedLabels]);

  const updateLabelMutation = async (label: Label) => {
    await labelUpdateMutation.mutateAsync({
      ...label,
      workspaceSlug: workspace.slug,
      orgIdOrSlug: workspace.organizationId,
    });
  };

  const updateTaskLabels = async (selectedLabels: Label[]) => {
    try {
      await taskMutation.mutateAsync({
        id: task.id,
        orgIdOrSlug: workspace.organizationId,
        key: 'labels',
        data: selectedLabels,
        projectId: task.projectId,
      });
      return;
    } catch (err) {
      toast.error(t('common:error.update_resource', { resource: t('app:task') }));
    }
  };

  const handleSelectClick = useCallback(
    async (value?: string) => {
      if (!value) return;
      setSearchValue('');
      if (!isOnline) dropdowner.remove();
      const existingLabel = selectedLabels.find((label) => label.name === value);
      if (existingLabel) {
        const updatedLabel = { ...existingLabel, useCount: existingLabel.useCount - 1 };
        const updatedLabels = selectedLabels.filter((label) => label.id !== updatedLabel.id);

        if (creationValueChange) return creationValueChange(updatedLabels);

        setSelectedLabels(updatedLabels);
        await updateTaskLabels(updatedLabels);
        await updateLabelMutation(updatedLabel);
        return;
      }

      const newLabel = orderedLabels.find((label) => label.name === value);
      if (newLabel) {
        const updatedLabel = { ...newLabel, useCount: newLabel.useCount + 1 };
        // Sort updated labels by name
        const updatedLabels = [...selectedLabels, updatedLabel].sort((a, b) => a.name.localeCompare(b.name));

        if (creationValueChange) return creationValueChange(updatedLabels);

        setSelectedLabels(updatedLabels);
        await updateTaskLabels(updatedLabels);
        await updateLabelMutation(updatedLabel);
      }
    },
    [orderedLabels, selectedLabels, workspace],
  );

  const handleCreateClick = async (value: string) => {
    setSearchValue('');
    if (orderedLabels.find((l) => l.name === value)) return handleSelectClick(value);

    if (!isOnline) dropdowner.remove();
    const createdLabel = await labelCreateMutation.mutateAsync({
      //Label variables
      id: nanoid(),
      name: value,
      color: '#FFA9BA',
      entity: 'label',
      projectId: task.projectId,
      lastUsedAt: new Date().toISOString(),
      //labels use count will increase on task creation
      useCount: creationValueChange ? 0 : 1,
      //Mutate variables
      workspaceSlug: workspace.slug,
      orgIdOrSlug: workspace.organizationId,
      isOnline,
    });
    // Sort updated labels by name
    const updatedLabels = [...selectedLabels, createdLabel].sort((a, b) => a.name.localeCompare(b.name));
    setSelectedLabels(updatedLabels);
    createdLabelsRef.current = [...createdLabelsRef.current, createdLabel];
    if (creationValueChange) return creationValueChange(updatedLabels);
    await updateTaskLabels(updatedLabels);
  };

  //when removing selectedLabels, switch to recent labels mode
  useEffect(() => {
    if (selectedLabels.length) return;
    setIsRecent(true);
  }, [selectedLabels]);

  useEffect(() => {
    setSelectedLabels(task.labels);
  }, [task.labels]);

  useEffect(() => {
    return () => mutateQuery.create(createdLabelsRef.current, 'label', 'labels');
  }, []);

  useEffect(() => {
    // save to recent labels all labels that used in past 3 days
    const recentLabels = orderedLabels.filter((l) => dateIsRecent(l.lastUsedAt, 3));
    changeColumn(workspace.id, task.projectId, { recentLabels });
    const unsubscribe = onlineManager.subscribe((online) => setIsOnline(online));
    return () => unsubscribe();
  }, []);

  return (
    <Command loop shouldFilter={false} className="relative rounded-lg max-h-[44vh] overflow-y-auto" style={{ width: `${triggerWidth}px` }}>
      <CommandInput
        ref={inputRef}
        autoFocus={!isMobile}
        value={searchValue}
        onValueChange={(searchValue) => {
          const labelsNum = showedLabels.length;
          // If the label types a number, select the label like useHotkeys
          if (inNumbersArray(labelsNum < 8 ? labelsNum : 8, searchValue))
            return handleSelectClick(showedLabels[Number.parseInt(searchValue) - 1]?.name);
          // Replace spaces with underscores only if the search string is not empty
          const updatedValue = searchValue.trim() ? searchValue.replaceAll(' ', '_') : searchValue;
          setSearchValue(updatedValue.toLowerCase());
        }}
        wrapClassName={`${!isRecent && 'max-sm:hidden'}`}
        clearValue={setSearchValue}
        className="leading-normal min-h-10"
        placeholder={showedLabels.length ? t('app:placeholder.search_labels') : t('app:create_label.text')}
      />
      {!searchValue && <Kbd value="L" className="max-sm:hidden absolute top-3 right-2.5" />}
      <CommandList>
        <CommandEmpty className="text-muted text-sm flex items-center justify-center px-3 py-1.5">
          {t('common:no_resource_yet', { resource: t('app:labels').toLowerCase() })}
        </CommandEmpty>
        <CommandGroup>
          {showedLabels.map((label, index) => (
            <CommandItem
              key={label.id}
              value={label.name}
              onSelect={(value) => {
                handleSelectClick(value);
              }}
              className="group rounded-md flex gap-2 justify-between items-center w-full leading-normal"
            >
              {searchValue || !isRecent ? (
                <Dot className="rounded-md text-white" size={14} style={badgeStyle(label.color)} strokeWidth={10} />
              ) : (
                <Tag
                  size={16}
                  className={`${selectedLabels.find((l) => l.id === label.id) ? 'opacity-100' : 'opacity-50'} group-hover:opacity-100`}
                />
              )}
              <div className="grow">{label.name}</div>
              <Check size={16} className={`text-success ${!selectedLabels.some((l) => l.id === label.id) && 'invisible'}`} />
              {!searchValue && <span className="max-sm:hidden text-xs opacity-50 mx-1">{index + 1}</span>}
            </CommandItem>
          ))}
        </CommandGroup>
        {!searchValue && !!showedLabels.length && creationValueChange === undefined && (
          <CommandItem
            className="flex justify-center text-xs m-1"
            onSelect={() => {
              setIsRecent(!isRecent);
              if (inputRef.current && !isMobile) inputRef.current.focus();
            }}
          >
            {t(`app:${isRecent ? 'show_selected_labels' : isMobile ? 'more_labels' : 'recent_labels'}`)}
          </CommandItem>
        )}
        {searchValue.trim() !== '' && (
          <CommandItem
            data-similar={[...orderedLabels, ...createdLabelsRef.current].some(({ name }) => name === searchValue)}
            className="flex justify-center text-xs m-1 data-[similar=true]:hidden"
            onSelect={() => handleCreateClick(searchValue)}
          >
            {t('common:create_resource', { resource: t('app:label').toLowerCase() })}
            <Badge className="ml-2 px-2 py-0 font-light flex" variant="plain">
              {searchValue}
            </Badge>
          </CommandItem>
        )}
      </CommandList>
    </Command>
  );
};

export default SetLabels;
