import { organizationQueryOptions } from '~/modules/organizations/organization-page';
import { workspaceQueryOptions } from '~/modules/workspaces/helpers/query-options';
import type { UserMenuItem } from '~/types/common';
import { tasksQueryOptions } from './modules/tasks/board/board-column';

// Set query client provider queries
export const userMenuPrefetchConfig = {
  organization: {
    queryOptions: organizationQueryOptions,
    prefetchMembers: true,
    prefetchAttachments: true,
  },
  workspace: {
    queryOptions: workspaceQueryOptions,
    prefetchMembers: false,
    prefetchAttachments: false,
  },
  project: {
    queryOptions: tasksQueryOptions,
    prefetchMembers: false,
    prefetchAttachments: false,
  },
} as const;

export const mapQuery = (item: UserMenuItem) => {
  switch (item.entity) {
    case 'organization':
      return organizationQueryOptions(item.slug);
    case 'workspace':
      return workspaceQueryOptions(item.slug, item.organizationId as string);
    case 'project':
      return tasksQueryOptions({
        projectId: item.id,
        orgIdOrSlug: item.organizationId as string,
      });
  }
};
