import { useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

import { useBreakpoints } from '~/hooks/use-breakpoints';
import { useEventListener } from '~/hooks/use-event-listener';
import { taskKeys } from '~/modules/common/query-client-provider/mutations/tasks';
import { setTaskCardFocus } from '~/modules/tasks/helpers';
import TaskCard from '~/modules/tasks/task';
import { useThemeStore } from '~/store/theme';

import { queryOptions, useQuery } from '@tanstack/react-query';
import { getTask } from '~/api/tasks';
import TasksHotkeysManager from '~/modules/tasks/tasks-hotkeys';
import type { TaskStates, TaskStatesChangeEvent } from './types';

export const taskQueryOptions = (id: string, orgIdOrSlug: string) =>
  queryOptions({
    queryKey: taskKeys.single(id),
    queryFn: () => getTask({ id, orgIdOrSlug }),
  });

const TaskSheet = ({ id, orgId }: { id: string; orgId: string }) => {
  const navigate = useNavigate();
  const { mode } = useThemeStore();
  const isMobile = useBreakpoints('max', 'sm');

  // Query task
  const { data: task } = useQuery(taskQueryOptions(id, orgId));

  const [state, setState] = useState<TaskStates>(isMobile ? 'expanded' : 'editing');

  const handleTaskState = (event: TaskStatesChangeEvent) => {
    const { taskId, state, sheet } = event.detail;

    if (!sheet || taskId !== id) return;
    if (state === 'currentState') return setState('expanded');
    setState(state);
  };

  useEventListener('changeTaskState', handleTaskState);

  useEffect(() => {
    setTaskCardFocus(`sheet-card-${id}`);
    // Add search parameter on mount
    navigate({
      to: '.',
      replace: true,
      resetScroll: false,
      search: (prev) => ({
        ...prev,
        taskIdPreview: id,
      }),
    });

    // Cleanup function to remove search parameter on unmount
    return () => {
      navigate({
        to: '.',
        replace: true,
        resetScroll: false,
        search: (prev) => {
          const { taskIdPreview, ...rest } = prev;
          return rest;
        },
      });
    };
  }, []);

  if (!task) return null;

  return (
    <>
      <TasksHotkeysManager mode={'sheet'} />
      <TaskCard mode={mode} task={task} state={state} isSelected={false} isFocused={true} isSheet />
    </>
  );
};

export default TaskSheet;
